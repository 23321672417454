<template>
  <div id="EmailConfirmation">
    <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-lines bg-cover bg-center">
      <div class="max-w-md w-full space-y-8">
        <div class="text-shadow">
          <img class="mx-auto h-28 w-auto m-3" src="@/assets/logo_border.png" alt="Workflow" />
          <div class="bg-gray-800 rounded-lg mt-6 pb-6 p-3">
            <h2 class="mt-4 text-center text-3xl font-extrabold text-white">
              E-Mail Adresse bestätigen
            </h2>
            <p class="mt-2 text-center  text-gray-300" v-show="!isLoading">
              <span v-show="isSuccess">
              Du hast Deine E-Mail Adresse erfolgreich bestätigt.<br>Vielen Dank!<br>
              <a class="link text-sm" href="/dashboard" target="_newTab">Zum Dashboard</a>
              </span>
              <span v-show="!isSuccess">
                Der Bestätigungscode ist abgelaufen oder ungültig.<br>
                <a class="link text-sm" href="https://app.stadtbahnfahrer.club/hilfe" target="_newTab">Benötigst Du Hilfe? Kontaktiere uns gerne.</a>
              </span>
            </p>
            <p class="mt-2 text-center  text-gray-300" v-show="isLoading">
              Einen Moment bitte...
            </p>
          </div>
          <p class="text-center text-sm text-gray-300">Code: {{this.$route.params.code}}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: "EmailConfirmation",
  components: {

  },
  data() {
    return {
      isSuccess: false,
      isLoading: true,
    };
  },
  methods: {},
  mounted() {
    this.$store
      .dispatch("confirmEmailAddress", this.$route.params.code)
      .then((a) => {
        this.isSuccess = a.status;

        this.isLoading = false;
      });
  },
  created() {},
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

